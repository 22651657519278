@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@function implode($pieces, $glue: '') {
  $result: null;
  @for $i from 1 through length($pieces) {
    $piece: nth($pieces, $i);
    @if type-of($piece) == list {
      $result: unquote('#{$result}#{$glue}#{implode($piece, $glue)}');
    } @else {
      $result: unquote('#{$result}#{$glue}#{$piece}');
    }
  }
  @if $result != null {
    $result: str-slice($result, str-length($glue) + 1, -1);
  }
  @return $result;
}
