@import '../../styles/util';
@import '../../styles/variables';

.Text {
  text-wrap: pretty;

  &.break-all {
    word-break: break-all;
  }

  &.no-wrap {
    white-space: nowrap;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.balance {
    text-wrap: balance;
  }

  &.theme-1 {
    color: var(--color-1);
  }

  &.theme-2 {
    color: var(--color-2);
  }

  &.theme-3 {
    color: var(--color-3);
  }

  &.theme-4 {
    color: var(--color-4);
  }

  &.theme-5 {
    color: var(--color-5);
  }

  &.theme-grey {
    color: var(--color-grey);
  }

  &.theme-black {
    color: var(--color-black);
  }

  &.theme-white {
    color: var(--color-white);
  }

  &.theme-error {
    color: var(--color-error);
  }

  &.clamp-1 {
    @include line-clamp(1);
  }

  &.clamp-2 {
    @include line-clamp(2);
  }

  &.clamp-3 {
    @include line-clamp(3);
  }
}
