.Fill,
.Fit {
  --cw: 400px;

  display: grid;
  gap: var(--spacing-2);
  align-items: stretch;

  &.ai-start {
    align-items: start;
  }

  &.ai-center {
    align-items: center;
  }

  &.ai-end {
    align-items: end;
  }

  &.ai-stretch {
    align-items: stretch;
  }
}

.Fill {
  grid-template-columns: repeat(auto-fill, minmax(min(var(--cw), 100%), 1fr));
}

.Fit {
  grid-template-columns: repeat(auto-fit, minmax(min(var(--cw), 100%), 1fr));
}

@media only screen and (width >= $tablet-portrait) {
  .Fill,
  .Fit {
    gap: var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Fill,
  .Fit {
    gap: var(--spacing-3);
  }
}
