// Must match the ColumnSets type in the Grid component.
$column-sets: (
  (4),
  (6),
  (7),
  (8),
  (10),
  (11),
  (12),
  (1 11),
  (2 10),
  (10 1),
  (10 2),
  (3 5),
  (3 7),
  (3 9),
  (4 4),
  (4 6),
  (4 8),
  (5 7),
  (7 3),
  (7 5),
  (6 3),
  (6 6),
  (9 3),
  (8 4),
  (8 3),
  (8 2),
  (1 10 1),
  (1 8 3),
  (2 6 4),
  (2 7 3),
  (2 8 2),
  (3 5 2),
  (3 5 4),
  (3 6 3),
  (4 4 4),
  (4 3 5),
  (5 2 5),
  (5 3 4),
  (5 5 1),
  (2 4 4 2),
  (2 3 5 2),
  (2 3 3 4),
  (1 5 5 1),
  (1 6 4 1)
);

.Grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-2);
  width: 100%;
  align-items: start;
  margin: 0 auto;
  max-width: var(--max-width-page);

  --offset: 0;

  &.ai-start {
    align-items: start;
  }

  &.ai-center {
    align-items: center;
  }

  &.ai-end {
    align-items: end;
  }

  &.ai-stretch {
    align-items: stretch;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Grid {
    gap: var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Grid {
    gap: var(--spacing-4);
  }
}

@mixin create-column-sets($prefix: '', $sets) {
  @each $sizes in $sets {
    .#{$prefix} {
      grid-template-columns: repeat(12, 1fr);
    }

    .#{$prefix}-#{implode($sizes, '-')} {
      $start: 1;
      $i: 1;
      @each $size in $sizes {
        $end: $start + $size;

        & > *:nth-child(#{$i}n) {
          grid-column-start: calc(#{$start} + var(--offset));
          grid-column-end: calc(#{$end} + var(--offset));
        }

        $start: $end;
        $i: $i + 1;
      }
    }
  }
}

@include create-column-sets('col', $column-sets);

@media only screen and (width >= $tablet-portrait) {
  @include create-column-sets('col-md', $column-sets);
}

@media only screen and (width >= $tablet-landscape) {
  @include create-column-sets('col-lg', $column-sets);
}

@media only screen and (width >= 1216px) {
  @include create-column-sets('col-xl', $column-sets);
}
