@import '../../styles/variables';

.Footer {
  display: grid;
  padding: var(--spacing-4) var(--spacing-2);
  max-width: var(--max-width-page);
  margin: 0 auto;
  width: 100%;
  gap: var(--spacing-4);
}

.FooterInner {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  gap: var(--spacing-4);

  div {
    display: grid;
    align-items: flex-start;
    gap: var(--spacing-2);
  }

  h2 {
    font-size: 1.7rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  nav {
    display: grid;
    gap: var(--spacing-2);
  }

  a {
    text-decoration: none;
    font-weight: 600;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Footer {
    padding: var(--spacing-4) var(--spacing-3);
  }

  .FooterInner {
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-3);
  }
}

@media only screen and (width >= $tablet-landscape) {
  .Footer {
    padding: var(--spacing-5) var(--spacing-4);
  }

  .FooterInner {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
