.AvatarLink {
  --background: var(--color-1);
  --size: 4rem;

  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background-color: var(--background);
  background-size: cover;
  background-position: center;
  text-indent: -9999px;
  position: relative;
  flex-shrink: 0;

  span {
    position: absolute;
    top: -4px;
    right: -4px;
    text-indent: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-2);

    --badge-size: 1.6rem;
    width: var(--badge-size);
    height: var(--badge-size);
    line-height: var(--badge-size);
    border-radius: var(--badge-size);
    font-size: 1.2rem;
    color: #fff;
  }

  b {
    position: absolute;
    inset: 1px;
    background-color: var(--color-white);
    mask-image: url(/svg/avatar/avatar_1.svg);
    mask-repeat: no-repeat;
    mask-position: center 42%;
    mask-size: 60%;
  }

  &.size-medium {
    --size: 6.4rem;

    span {
      top: 0;
      right: 0;
    }
  }

  &.size-large {
    --size: 8.8rem;

    span {
      top: 2px;
      right: 2px;
      width: 2rem;
      height: 2rem;
    }
  }
}
