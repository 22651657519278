@import '../../styles/variables';
@import '../../styles/util';

.with-icon {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-1);

  @media only screen and (width < $tablet-portrait) {
    &.icon-only-mobile {
      span:not(:first-child) {
        display: none;
      }
    }
  }

  @media only screen and (width < $tablet-landscape) {
    &.icon-only-tablet {
      span:not(:first-child) {
        display: none;
      }
    }
  }
}

.with-underline {
  text-decoration: underline;
}
