@mixin create-min-column-widths($prefix: '') {
  $smallSizes: (32 40 48 64 80);

  @each $size in $smallSizes {
    .#{$prefix}-#{$size} {
      --cw: #{$size}px;
    }
  }

  @for $i from 10 through 44 {
    $width: $i * 10;

    .#{$prefix}-#{$width} {
      --cw: #{$width}px;
    }
  }
}

@include create-min-column-widths('min');

@media only screen and (width >= 300px) {
  @include create-min-column-widths('min-sm');
}

@media only screen and (width >= $tablet-portrait) {
  @include create-min-column-widths('min-md');
}

@media only screen and (width >= $tablet-landscape) {
  @include create-min-column-widths('min-lg');
}

@media only screen and (width >= $max-width-page) {
  @include create-min-column-widths('min-xl');
}
