.Textarea {
  display: grid;
  gap: 0.8rem;
  color: var(--color-black);
  text-align: left;

  label {
    line-height: 1.6rem;
    font-weight: 600;

    span {
      color: var(--color-1);
    }
  }

  > div {
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--color-black);
    border-radius: var(--border-radius-1);
    overflow: hidden;

    &:focus-within {
      border-color: var(--color-2);
      box-shadow: 0 0 0 4px #e6f2fb;
    }
  }

  textarea {
    width: 100%;
    padding: 1rem 0.8rem 1rem 1.2rem;
    border: 0;
    font-size: 1.6rem;
    font-family: inherit;
    font-family: var(--font-family-1), 'Arial', Helvetica Neue, sans-serif;
    border-radius: var(--border-radius-1);
    background-color: var(--color-white);

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: #93a3b2;
    }

    &[readonly] {
      background-color: var(--color-4);
    }

    &:-webkit-autofill {
      background-color: var(--color-4);
    }
  }
}
