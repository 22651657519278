@import '../../styles/variables';

.Nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  transition: background-color 200ms ease-in-out;

  .NavInner {
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
  }

  &:not([inert]) {
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.4);

    .NavInner {
      transform: translateX(0%);
    }
  }
}

.NavInner {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--color-4);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-3);
  overflow: auto;
  overscroll-behavior: none;
  display: flex;

  svg {
    width: 4rem;
    height: 4rem;

    @media only screen and (width >= $tablet-portrait) {
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  > div {
    background-color: var(--color-4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-2);
    font-size: 1.8rem;
    width: 100%;

    button {
      width: 2.4rem;
      height: 2.4rem;
      text-indent: -9999px;
      background: transparent url(/svg/icon/icon_cross_heavy.svg) no-repeat
        center;
    }
  }

  nav {
    padding: 0;
    gap: var(--spacing-3);
    display: grid;
    width: 100%;
    max-width: 20rem;
  }
}
