@import '../../styles/util';
@import '../../styles/variables';

.Header {
  background-color: var(--color-4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-2);
  font-size: 1.8rem;

  svg {
    width: 4rem;
    height: 4rem;

    @media only screen and (width >= $tablet-portrait) {
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  div,
  nav {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
  }

  div a:not(:first-child) {
    display: none;
  }

  a,
  button {
    font-weight: 600;
    color: var(--color-1);
    text-decoration: none;
  }

  nav {
    display: none;
  }

  > button {
    width: 2.4rem;
    height: 2.4rem;
    text-indent: -9999px;
    background: transparent url(/svg/icon/icon_hamburger.svg) no-repeat center;
    position: relative;

    span {
      --size: 1.6rem;
      text-indent: 0;
      background-color: var(--color-2);
      display: flex;
      justify-content: center;
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      border-radius: var(--size);
      color: #fff;
      position: absolute;
      top: -4px;
      right: -6px;
      font-size: 1.2rem;
    }
  }
}

@media only screen and (width >= $tablet-portrait) {
  .Header {
    padding: var(--spacing-3);

    div a:not(:first-child) {
      display: unset;
    }

    nav {
      display: flex;
    }

    > button {
      display: none;
    }
  }
}
