.Input {
  display: grid;
  gap: 0.8rem;
  color: var(--color-black);
  text-align: left;

  label {
    line-height: 1.6rem;
    font-weight: 600;

    span {
      color: var(--color-1);
    }
  }

  > div {
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--color-black);
    border-radius: var(--border-radius-1);
    overflow: hidden;

    &:focus-within {
      border-color: var(--color-2);

      // Shadow based outline that respects border-radius
      outline: 0;
      box-shadow: 0 0 0 4px #e6f2fb;
    }

    > span,
    > button {
      align-self: center;
      justify-self: center;
    }

    &:has(input[readonly]) {
      background-color: var(--color-4);
    }
  }

  &.with-append div,
  &.with-prepend div {
    display: grid;
  }

  &.with-prepend div {
    grid-template-columns: 4.8rem 1fr;
  }

  &.with-append div {
    grid-template-columns: 1fr 4.8rem;
  }

  &.with-append.with-prepend div {
    grid-template-columns: 4.8rem 1fr 4.8rem;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='date'],
  input[type='number'] {
    width: 100%;
    padding: 1rem 0.8rem 1rem 1.2rem;
    border: 0;
    font-size: 1.6rem;
    font-family: inherit;
    font-family: var(--font-family-1), 'Arial', Helvetica Neue, sans-serif;
    border-radius: var(--border-radius-1);
    background-color: var(--color-white);

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &::placeholder {
      color: #93a3b2;
    }

    &[readonly] {
      background-color: var(--color-4);
    }

    &[disabled] {
      color: #aaa;
    }

    &:-webkit-autofill {
      background-color: var(--color-4);
    }

    &[maxlength='4'] {
      flex-grow: 1;
    }
  }

  @each $i in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10) {
    &.size-#{$i} {
      max-width: #{4.2 + ($i * 0.8)}rem;
    }
  }
}
