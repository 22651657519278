@import '../../styles/variables';

.ModalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(19, 21, 22, 0.5);
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

.ModalContainer {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  pointer-events: none;
  padding: var(--spacing-2);
  overflow: hidden;

  .Modal {
    background-color: #fff;
    width: 100%;
    max-width: 54rem;
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    transform: translateY(16rem);
    opacity: 0;
    box-shadow: var(--box-shadow-4);
    border-radius: var(--border-radius-2);
    margin: auto;
    position: relative;
    container: modal / inline-size;
  }

  .ModalBody {
    padding: 5.6rem var(--spacing-3) var(--spacing-3) var(--spacing-3);
    min-height: 12rem;
    position: relative;
    overflow: hidden;

    @media only screen and (width >= $tablet-portrait) {
      padding: 7.2rem var(--spacing-4) var(--spacing-4) var(--spacing-4);
    }
  }

  &.open {
    transform: translateZ(0);
    pointer-events: all;
    overflow: auto;

    .Modal {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.CloseButton {
  position: absolute;
  top: var(--spacing-3);
  right: var(--spacing-3);
  z-index: 1;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (width >= $tablet-portrait) {
    top: var(--spacing-4);
    right: var(--spacing-4);
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-black);
  }
}
