@import 'variables';
@import 'util';

@import 'grid';
@import 'grid-auto';
@import 'gridish';
@import 'flex';
@import 'offset';
@import 'gap';
@import 'min-column-width';

:root {
  // brand colors
  --color-1: #0017a0;
  --color-2: #00a6fe;
  --color-3: #b5c9db;
  --color-4: #f3faff;
  --color-5: #c9b18a;

  // other
  --color-black: #2a2a2a;
  --color-white: #fff;
  --color-error: #c44b46;
  --color-grey: #8e8e8e;

  // spacing
  --spacing-1: 0.8rem;
  --spacing-2: 1.6rem;
  --spacing-3: 2.4rem;
  --spacing-4: 3.2rem;
  --spacing-5: 4rem;
  --spacing-6: 4.8rem;
  --spacing-7: 5.6rem;
  --spacing-8: 6.4rem;

  // border radius
  --border-radius-1: 0.8rem;
  --border-radius-2: 1.6rem;
  --border-radius-3: 2.4rem;

  // specific sizes
  --max-width-page: #{$max-width-page};
}

html {
  // See override in _app.tsx
  --font-family-1: 'Trebuchet MS', 'Arial';

  // palette colors
  --palette-1: #41a5f7;
  --palette-2: #94c3c5;
  --palette-3: #91847d;
  --palette-4: #edcbd4;
  --palette-5: #c44b46;
  --palette-6: #e28e54;
  --palette-7: #788f76;
  --palette-8: #e5d055;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: var(--font-family-1), 'Arial', Helvetica Neue, sans-serif;
  line-height: 1.5;
  font-size: 62.5%; // So you can easily multiply rem * 10 to get to the px value.
  color: var(--color-black);
  font-variant-ligatures: none; // Prevent ligatures.
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 1.6rem;
}

.no-scroll,
.no-scroll body {
  overflow: hidden;
}

button {
  all: unset;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-family: var(--font-family-1), 'Arial', Helvetica Neue, sans-serif;
}

span[role='button'] {
  cursor: pointer;
}

strong {
  font-weight: 700;
}

a {
  color: var(--color-1);
  text-decoration: none;
  transition: color 100ms ease-in-out;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

a:hover {
  color: var(--color-1);
}

hr {
  display: block;
  height: 2px;
  background-color: #ececec;
  width: 100%;
  max-width: 38.8rem;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-1);
  font-size: 1.5rem;
}

h1,
h2 {
  font-size: 3.2rem;
  line-height: 3.2rem;
}

h3 {
  font-size: 2.4rem;
  line-height: 2.8rem;
}

h4 {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  user-select: none;
}

svg {
  display: block;
}

address {
  font-style: normal;
}

// Get rid of the outline for mouse users but preserve it for keyboard users.
:focus:not(:focus-visible) {
  outline: 0;
}

// Also no outline on focussable divs.
div:focus-visible {
  outline: 0;
}

// default ul style without any specifity.
:where(ul) {
  list-style-position: outside;
  padding-left: 1.6rem;
}

// Add visible outline for keyboard users only.
@media (hover: hover) {
  :focus-visible {
    outline: 4px solid #e6f2fb;
  }
}

::selection {
  background-color: #b5c9db;
}

// Make footer sticky at the bottom on pages with not enough content.
// This asumes #__next always has exactly 4 children.
#__next {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

/* Default form styles */

textarea,
input[type='text'],
input[type='url'],
input[type='email'],
input[type='tel'],
input[type='date'],
input[type='number'] {
  appearance: none;
  background-color: #fff;
  min-height: 4.2rem;
  text-align: left;
  padding: 0 1.2rem;
  font-size: 1.5rem;
  font-family: inherit;
  font-family: var(--font-family-1);
  border: 1px solid var(--color-black);
  border-radius: var(--spacing-1);
  width: 100%;
  display: block;

  &[maxlength='1'] {
    text-align: center;
  }

  &:focus {
    border-color: var(--color-2);

    // Shadow based outline that respects border-radius
    outline: 0;
    box-shadow: 0 0 0 4px #e6f2fb;
  }
}

textarea {
  padding: 1.2rem;
}

input[type='checkbox'],
input[type='radio'] {
  accent-color: var(--color-black);
}

input[type='radio'][disabled] + label {
  color: #aaa;
}

input[type='radio'] + label i {
  color: var(--color-1);
}

input:-webkit-autofill,
input:-webkit-autofill::first-line,
input:-webkit-autofill:focus {
  font-family: var(--font-family-1);
}

select {
  appearance: none;
  border: 1px solid var(--color-black);
  min-height: 4.2rem;
  text-align: left;
  display: block;
  border-radius: var(--spacing-1);
  color: var(--color-black);
  padding: 0 4rem 0rem 1.4rem;
  background-size: 2rem;
  font-size: 1.6rem;
  font-family: inherit;
  font-family: var(--font-family-1);
  background: var(--color-white) url(/svg/icon/icon_chevron_down.svg) center
    right 0.8rem no-repeat;

  &:focus {
    border-color: var(--color-2);

    // Shadow based outline that respects border-radius
    outline: 0;
    box-shadow: 0 0 0 4px #e6f2fb;
  }
}

label span {
  color: var(--color-1);
}
