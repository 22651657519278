@keyframes logo-animation-n {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes logo-animation-h {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.Logo {
  overflow: visible;
}

.LogoN {
  transform-origin: center;
  animation: logo-animation-n 1.5s ease-out 0s forwards;
}

.LogoH {
  opacity: 0;
  transform-origin: center;
  animation: logo-animation-h 0.75s ease-out 0.75s forwards;
}
