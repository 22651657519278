.Button {
  width: 4.8rem;
  height: 4.8rem;
  background-color: var(--color-1);
  display: flex;
  border-radius: 100vw;
  justify-content: center;
  align-items: center;

  @each $i in (1, 2, 3, 4, 5, 6, 7, 8) {
    &.size-#{$i} {
      width: #{$i * 0.8}rem;
      height: #{$i * 0.8}rem;
    }
  }

  &.theme-secondary {
    background-color: var(--color-4);
    border: 1px solid var(--color-1);
  }

  &.theme-compact {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0;
    background-color: transparent;
  }
}
