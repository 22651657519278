@mixin create-offsets($prefix: '') {
  @for $offset from 0 through 6 {
    .#{$prefix}-#{$offset} {
      --offset: #{$offset};
    }
  }
}

@include create-offsets('offset');

@media only screen and (width >= $tablet-portrait) {
  @include create-offsets('offset-md');
}

@media only screen and (width >= $tablet-landscape) {
  @include create-offsets('offset-lg');
}

@media only screen and (width >= $max-width-page) {
  @include create-offsets('offset-xl');
}
