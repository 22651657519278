@use 'sass:math';

@import '../../styles/variables';
@import '../../styles/util';

$line-width: 12px;
$line-width-2: ($line-width * 2);
$line-offset: math.hypot($line-width-2, $line-width-2) * -1;

.Button {
  background-color: var(--color-1);
  border: 1px solid var(--color-1);
  color: var(--color-white);
  padding: 0.6rem 1.6rem;
  min-height: 4rem;
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 100vw;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: flex-start;
  gap: 0.8rem;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  isolation: isolate;
  flex-shrink: 0;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &.shape-square {
    border-radius: 4px;
    font-weight: 400;
    padding: 0.6rem 1.2rem;
  }

  &.theme-secondary {
    background-color: var(--color-4);
    border: 1px solid var(--color-1);
    color: var(--color-1);
  }

  &.theme-outline {
    background-color: transparent;
    border: 1px solid var(--color-1);
    color: var(--color-1);
  }

  &.theme-warn {
    background-color: var(--color-error);
    border: 1px solid var(--color-error);
    color: var(--color-white);
  }

  /* Size variations */
  &.size-small {
    min-height: 3.2rem;
    padding: 0 1.4rem 0 1.4rem;
  }

  &.size-large {
    min-height: 5.6rem;
    padding: 0 1.4rem 0 1.4rem;
  }

  /* Alignment */
  &.align-left {
    align-self: flex-start;
  }

  &.align-center {
    align-self: center;
  }

  &.align-right {
    align-self: flex-end;
  }

  &.icon-position-right {
    flex-direction: row-reverse;
  }

  /* Icon only */
  @media only screen and (width < $tablet-portrait) {
    &.icon-only-mobile {
      .Label {
        display: none;
      }
    }
  }

  @media only screen and (width < $tablet-landscape) {
    &.icon-only-tablet {
      .Label {
        display: none;
      }
    }
  }

  /* Loading */
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;
    overflow: hidden;
    opacity: 0.5;

    &::before {
      opacity: 1;
      background-image: repeating-linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.09) 0px,
        rgba(255, 255, 255, 0.09) $line-width,
        rgba(255, 255, 255, 0) $line-width,
        rgba(255, 255, 255, 0) $line-width-2
      );
      background-repeat: repeat;
      background-attachment: fixed;
      animation: moveLines 500ms linear infinite;
    }
  }

  &.theme-secondary,
  &.theme-outline {
    &.loading::before {
      background-image: repeating-linear-gradient(
        -45deg,
        rgba(181, 201, 219, 0.3) 0px,
        rgba(181, 201, 219, 0.3) $line-width,
        rgba(181, 201, 219, 0) $line-width,
        rgba(181, 201, 219, 0) $line-width-2
      );
    }
  }
}

.Badge {
  --size: 1.6rem;
  background-color: var(--color-2);
  display: flex;
  justify-content: center;
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  color: #fff;
  position: absolute;
  top: -6px;
  right: 4px;
  font-size: 1.2rem;
}

@container (max-width: 768px) {
  .Button.icon-only-tablet {
    .Label {
      display: none;
    }
  }
}

.ButtonGroup {
  display: flex;
  gap: var(--spacing-2);
}

@keyframes moveLines {
  from {
    background-position-x: 0;
  }

  to {
    background-position: $line-offset;
  }
}
