@import '../../styles/util';
@import '../../styles/variables';

.H {
  &.h1 {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  &.h2 {
    font-size: 3.2rem;
    line-height: 3.4rem;
  }

  &.h3 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  &.h4 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  &.h5 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &.h6 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.balance {
    text-wrap: balance;
  }

  &[role='button'] {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  &.clamp-1 {
    @include line-clamp(1);
  }

  &.clamp-2 {
    @include line-clamp(2);
  }

  &.clamp-3 {
    @include line-clamp(3);
  }
}
